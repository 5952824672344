@import '../../Colors.less';

.sectorChild {
  h4 {
    color: @color-primary;
  }
  svg {
    filter: none;
  }
  .sectorTitle {
    color: @color-primary;
  }
}

.sectorItem:hover {
  background-color: @white-hex;
  box-shadow: 0 0.25rem 3.75rem 0 rgb(0 0 0 / 16%);
  .sectorChild;
}
.sectorContent {
  min-height: 100px;
}

.sectorIcon {
  svg {
    height: 40px;
    width: 40px;
    filter: grayscale(100%);
  }
}

.homenew {
  .sectorItem {
    display: block !important;
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
  .sectorItem:hover {
    box-shadow: none;
    .sectorChild;
  }

  .sectorIcon {
    svg {
      height: 35px;
      width: 35px;
      filter: grayscale(100%);
    }
  }
}
.homenewMobile {
  .sectorItem {
    display: block !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.homenewTablet {
  .sectorItem {
    display: block !important;
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
  }
}
.borderBottom,
.borderRight {
  position: absolute;
  top: 0px;
  width: 1px;
  height: 100%;
  border: 1px solid @solitude-hex;
  right: 0;
}
.borderBottom {
  width: 100%;
  border: 1px solid @solitude-hex;
  height: 1px;
  bottom: 0;
  top: auto;
}
.sectorTitle {
  color: @color-black;
}

@primary-color: #2E5CF6;