@import '../../Colors.less';

.closeButton {
  color: @color-medium-grey !important;
  font-size: 16px;
}

.closeButtonBlack {
  color: @color-medium-grey !important;
  font-size: 21px;
}

.closeButtonWhite {
  color: @color-white !important;
  font-size: 21px;
}

.closeButtonBlue {
  color: @color-primary !important;
  font-size: 21px;
}
.closeButtonRed {
  color: @color-red-dark !important;
  font-size: 21px;
}

@primary-color: #2E5CF6;