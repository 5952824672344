@import '../../Colors.less';

:global {
  .location-tree-container {
    height: 100%;
  }

  .location-tree-item {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    color: @color-darkish-grey;
    padding: 8px 15px 8px 15px;
    min-height: 30px;
  }

  .location-tree-item:hover {
    background-color: @color-tree-node-border-color;
  }

  .location-tree-row {
    position: relative;
  }

  .location-tree-row.open {
    background-color: @color-whittesh;
  }

  .location-tree-container > .location-tree-row > .location-tree-item .location-tree-name {
    font-weight: 500;
  }

  .location-tree-children {
    padding-left: 1.4em;
    position: relative;
  }

  .location-tree-children .location-tree-row {
    border-left: 1px solid @color-tree-node-border-color;
  }

  .location-tree-icon {
    margin-right: 8px;
    font-size: 10px;
    cursor: pointer;
    color: @color-medium-grey;
    align-self: center;
  }

  .location-tree-row .location-tree-item .location-tree-icon svg {
    transition: transform 0.3s;
  }

  .location-tree-row.closed .location-tree-item .location-tree-icon svg {
    transform: rotate(-90deg);
  }

  .location-tree-container > .location-tree-row {
    border-bottom: 1px solid @color-tree-node-border-color;
  }

  .location-tree-name {
    max-width: 59%;
    display: inline-block;
    cursor: pointer;
  }

  .location-tree-children .location-tree-row::before {
    content: '';
    width: 0.8rem;

    height: 1px;
    position: absolute;
    left: 0;
    top: 1.222rem;
  }

  .availableCount {
    color: @color-medium-grey;
  }

  .search-popover .location-recommedndationListItem {
    .ant-tag {
      line-height: 24px;
    }
  }

  .search-popover .location-recommedndationListItem:hover {
    background: @color-whittesh !important;

    .ant-tag {
      background-color: @color-white-lighter !important;
      line-height: 24px;
    }
  }

  .location-recommedndationList-StyleType::before {
    background-color: @ant-border-hex;
    margin-bottom: 2px;
  }

  .location-recommedndationList-MetaInfo {
    color: @color-medium-grey;
  }

  .search-popover #searchList {
    height: 35vh;
  }

  .location-search-tag {
    padding: 4px 12px !important;
  }

  .location-add-button {
    min-height: 20px;
    max-height: 20px;
    font-size: 12px !important;
  }
  .filterContainer {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
  .defaultHeight {
    height: 50vh;
  }
  .visualize-height {
    height: 38vh;
  }
  .mobileHeight {
    height: 59vh;
  }
}

@primary-color: #2E5CF6;