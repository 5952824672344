@import '../../Colors.less';

.commonPaginationMobile {
  :global {
    .ant-pagination.paginationStyle {
      justify-content: center !important;
    }
    .ant-pagination-prev {
      margin-right: 8px !important;
    }
    .ant-pagination-prev button,
    .ant-pagination-next button {
      font-size: 13px;
    }
    .ant-pagination-item {
      min-width: 32px !important;
      max-width: 44px !important;
      height: 26px !important;
      line-height: 22px !important;
      a{
        padding: 0 3px;
        font-size: 13px;
      }
    }
  }
}

:global {
  .paginationStyle {
    .ant-pagination-item {
      border-radius: 8px;
    }

    .ant-pagination-item-active {
      background: @color-lighten-grey;
    }
    .ant-pagination-options-quick-jumper {
      input {
        border-radius: 8px !important;
      }
    }
    .ant-pagination-prev {
      margin-right: 8px !important;
    }
    .ant-pagination-prev button,
    .ant-pagination-next button {
      border: none !important;
    }
    .ant-pagination-next {
      margin-left: 8px !important;
    }
  }
}

@primary-color: #2E5CF6;