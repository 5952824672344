@import url('../../../Colors.less');

.visualBtnWrapper {
  background-color: @color-white-lighter;
  margin: 2px;
  border-radius: 8px;
  color: @color-light-dark  !important;
  font-weight: 600 !important;
}

.level1 {
  margin: 5px !important;
}

.level2 {
  margin: 8px !important;
}

.visualBtn {
  color: @color-darkish-grey  !important;
}

.dashedBtn {
  border-radius: 8px !important;
}

.chartOutline {
  border: 1px solid @color-greyish-dark;
  border-radius: 12px;
  padding: 10px 0px;
  background-color: @color-lightish-grey;
}

.axisType {
  row-gap: 0px;
  border: 1px solid @color-greyish-dark;
  margin: 15px 0px;
  border-radius: 8px;
  padding: 5px 15px 15px;
}

.filterCheckbox {
  flex-direction: column;
}

.mergeSider {
  padding: 24px 24px 0px 0px;
  position: relative;

  .mergeListContainer {
    max-height: 52vh;
    min-height: 52vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
}

.mergeSider,
.mergeContent {
  background: @white-hex  !important;
  min-height: 42rem;
  overflow: hidden;
}

.mergeContent {
  padding: 24px;
  padding-right: 0px;
  overflow: auto !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  :global {

    .ant-modal-mask,
    .ant-modal-wrap {
      position: absolute;
    }
  }
}

:global {
  .visualisationFilter {
    .searchWrapperCustom {
      width: 48.5vw !important;
    }
  }

}
@primary-color: #2E5CF6;