@import '../../Colors.less';

.popupContainer {
  .suggestDataset {
    position: fixed;
    right: -62px;
    top: 418px;
    background: @color-blue;
    color: @white-hex !important;
    display: block;
    width: 10.063rem;
    height: 39px;
    cursor: pointer;
    z-index: 9999;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    padding: 9px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    font-weight: 500;
  }
  .suggestDatasetMobile {
    font-size: 0px !important;
    width: 50px !important;
    right: -6px;
    .mobileIcon {
      font-size: 16px !important;
    }
  }
  :global {
    .ant-form-item {
      margin-bottom: 15px !important;
    }
  }

  .closeIcon {
    color: @black-hex;
  }

  .paddedRow {
    padding: 10px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .suggestDatasetMobile {
    top: 270px !important;
  }
}

.suggestDatasetText{
  color:@color-white;
}
@primary-color: #2E5CF6;