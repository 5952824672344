@import '../../../Colors.less';


.dangerText {
  color: @color-red;
}
.scrollTopBtn {
  position: sticky;
  bottom: 29px;
  right: 0px;
  text-align: right;
  margin-right: 0px;
  .scrollBtn {
    background: @color-background !important;
    box-shadow: 1px 1px 6px @color-shadow-light;
    border-radius: 4px;
  }
}
.scrollTopBtnResponsive {
  margin-right: 0px !important;
}
.iconColor {
  color: @color-light-skyblue !important;
}
.indicatorColor {
  background-color: @color-light-skyblue !important;
  color: @white-hex !important;
  font-size: 10px !important;
  height: 15px !important;
  width: 15px !important;
}
.spanIcon {
  margin-top: -10px;
}
.indicatorColorPink {
  background-color: @color-medium-pink !important;
  color: @white-hex !important;
  font-size: 10px !important;
  height: 15px !important;
  width: 15px !important;
}
.iconColorpink {
  color: @color-medium-pink !important;
}
.locationIcon {
  color: @color-light-dark !important;
}
.subtext {
  color: @color-medium-grey !important;
}
.subtextColor {
  color: @color-medium-grey !important;
  margin-left: 5px !important;
}
.mainText {
  font-size: 14px;
}
.titleHeight {
  height: 72px;
  padding: 24px;
  background-color: @color-light-white;
  font-size: 16px;
  font-weight: 600;
}
.textColor {
  color: @color-icon;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
}
.filterOut {
  background-color: @color-light-white;
  color: @color-icon;
  font-size: 14px;
  font-weight: 400;
}
.excel {
  text-decoration: none;
  color: @color-primary !important;
  font-weight: 600;
}
.scrollBtn {
  color: @color-primary;
}
.keyValues {
  background: @color-whittesh;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  padding: 16px 5px;
  color: @color-darkish-grey;
  font-size: 14px;
  font-weight: 500;
}
.valuesTitle {
  min-height: 22px;
  padding-right: 20px;
}
:global {
  .meta-data-section {
    b {
      font-weight: 500 !important;
    }

    .meta-data-view {
      overflow: auto;
    }
  }
}
.breakWord {
  word-break: break-word
}

@primary-color: #2E5CF6;