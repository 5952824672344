@import '../../../Colors.less';
.reportInfo {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.reportSections {
  .reportMainCol + .reportMainCol {
    .reportValues {
      :global {
        .border-top {
          border-top: none !important;
        }
      }
    }
  }
}
.fixedHeader{
  line-height: 26px;
}
@primary-color: #2E5CF6;