@import '../../Colors.less';

.collectionListContainer {
  padding: 20px 0px;
  .collection {
    h3 {
      color: @color-primary;
    }
  }
}

:global {
  .collectionSearch {
    .ant-input-affix-wrapper {
      padding-left: 42px;
      border-radius: 4px !important;
      height: 40px;
      input::placeholder {
        font-size: 14px;
      }
    }
    .ant-input-affix-wrapper-focused {
      padding-left: 12px !important;
      + .ant-input-group-addon {
        display: none;
      }
    }
    .ant-input-group-addon {
      display: block;
      left: 8px !important;
      position: absolute;
      top: 5px;
      border: 0;
      z-index: 2;
    }
    .ant-input-search-button {
      border: 0;
      width: 30px;
    }
    .anticon-search {
      font-size: 16px;
      color: @color-darkish-grey;
    }
  }
}

@primary-color: #2E5CF6;