@import '../../Colors.less';

.responsiveContainer {
  background: @white-hex;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  &_root {
    margin-right: 0;
  }
  &_label {
    font-size: 0.8rem;
    &_bold {
      font-weight: bold;
    }
  }
  &_back {
    font-weight: 600;
    font-size: 1rem;
    &_link {
      text-decoration: none;
      color: @color-hightlight-text;
      text-align: center;
    }
  }
}

.rotateDiv {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: @color-darkish-grey;
  margin-bottom: 10px;
  &_rotateTxt {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
  }
  &_img {
    display: block;
    margin: 0px auto;
  }
  &_switch {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: @black-hex;
    margin-bottom: 8px;
  }
}

@primary-color: #2E5CF6;