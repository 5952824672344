@import '../../Colors.less';

.ministryList {
  .link {
    padding-right: 40px;
    display: block;
    position: relative;
    padding: 8px 20px 8px 8px;

    h4 {
      margin: 0px;
    }
    span {
      display: none;
      position: absolute;
      right: 0.6rem;
      top: 0.8rem;
    }
    &:hover {
      box-shadow: 0 0.25rem 3.75rem 0 rgb(0 0 0 / 16%);
      border-radius: 4px;
      span {
        display: block;
        transition: 0.5s;
      }
    }
  }
}

@primary-color: #2E5CF6;