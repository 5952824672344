@import '../../Colors.less';
.tooltipCard {
  background-color: @color-background;
  border-radius: 5px;
  box-sizing: border-box;
  color: @color-dark-black;
  font-size: 16px;
  max-width: 100%;
  padding: 15px;
  position: relative;
  width: 400px;
}
.tooltipCardHead {
  font-size: 18px;
  margin: 25px 0 0px;
}
.tooltipCardBody {
  padding: 20px 10px;
}
.tooltipCardFooter {
  button {
    border-radius: 4px;
  }
  .primaryBtn {
    background-color: @color-hightlight-text;
  }
}
.tooltipSkip {
  flex: 1 1 0%;
}
.tooltipClose {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 21px;
  line-height: 1;
  padding: 15px;
  appearance: none;
  position: absolute;
  right: 0px;
  top: 0px;
}
.joyrideTooltip {
  min-width: 400px !important;
  .introjs-tooltiptext {
    padding: 5px 20px !important;
  }
  
  .introjs-helperNumberLayer {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  
  .introjs-tooltipbuttons {
    padding: 5px 10px !important;
  }
  }
  
  .joyrideTooltip .introjs-tooltiptext {
    padding: 5px 20px !important;
}

.primaryBtn {
  &:focus {
    background-color: @color-hightlight-text  !important;
  }
}

@primary-color: #2E5CF6;