@import '../../Colors.less';

.datasetContainer {
  .header {
    margin-top: 20px;
  }

  .dataPreview {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  color: @black-hex;

  :global {
    .ant-statistic-content {
      font-size: 14px !important;
    }

    .ant-tabs-tab .anticon {
      margin-right: 0 !important;
    }
  }

  .keyIcon {
    color: @color-skyblue;
    margin-right: 5px;
  }

  .dataTabFilterRow {
    margin: 15px 0 15px 0;
  }

  .dataTab {
    .table {
      height: 500px;
      overflow-y: auto;
    }

    :global {
      .virtual-grid {
        width: 100% !important;
        // padding-left: 12px !important;
      }
    }
  }

  .metadataDownload {
    margin-bottom: 20px;
  }

  .viewProcessing {
    margin: 63px;
    margin-top: 20px;
    text-decoration: underline !important;
  }

  .jumpToMeta {
    color: @color-black-light;
    font-weight: bold;
  }

  .jumpToMeta:hover,
  .jumpToMeta:active,
  .jumpToMeta:focus {
    color: @color-black-light !important;
  }
}

.width60 {
  width: 60% !important;
}

.actionsMenu {
  z-index: 999 !important;

  ul {
    box-shadow: 0 0.25rem 3.75rem 0 rgb(0 0 0 / 16%) !important;
    padding: 11px 0px;

    li {
      padding: 0px;
      margin: 11px 0px !important;
      height: auto !important;
    }
  }
}
@primary-color: #2E5CF6;