@import '../../../Colors.less';

:global {
  .filterRow:hover {
    background-color: @solitude-hex !important;
  }
}
.filterContainer {
  margin-bottom: 5px;
  .tagIcon {
    border: none;
    background-color: @color-skyblue;
    color: @white-hex;
  }
  :global {
    .popContainer {
      height: 300px;
      max-height: calc(100% - 96px);
      overflow-y: auto;
      width: 800px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .ant-popover-arrow {
      display: none !important;
    }

    .ant-popover-content {
      border: 1px solid @solitude-hex;
      .switchText {
        margin-left: 5px !important;
        display: inline !important;
      }
    }

    .ant-badge-count {
      background: @color-primary;
    }
  }
}

@primary-color: #2E5CF6;