@import '../../Colors.less';

.tagStyleMixin {
  background: none;
  border: 1px solid @color-searchbar-icon !important;
  cursor: pointer;
  border-radius: 8px !important;
  padding: 4px 10px 4px 0px !important;
  display: inline-flex !important;
  align-items: center;
}

.searchbar {
  background-color: @white-hex;
  border-radius: 8px;

  .icon {
    color: @color-medium-grey;

    svg {
      font-size: 18px;
      margin-top: 3px;
    }
  }

  .iconDisabled {
    pointer-events: none;
  }

  .iconTransformed {
    svg {
      transform: rotate(90deg);
    }
  }

  :global {
    .ant-select-selector {
      height: auto !important;
      border: none !important;
      box-shadow: none !important;
    }

    .ant-select-selection-search {
      input {
        color: @color-gray-light;
        outline: 0;
        border: none;
        font-size: 25px;
        box-shadow: none;
        height: auto !important;
      }
    }

    .ant-select-selection-placeholder {
      font-size: x-large;
    }

    .ant-select-focused .ant-select-selection-placeholder {
      font-size: medium;
    }

    .ant-typography {
      div {
        display: 'inline' !important;
      }
    }
  }
}

.searchBarPopup {
  .searchInput {
    padding: 17px 12px;
    border-radius: 8px;
    height: 62px;
  }

  .searchIcon {
    width: 30px;
    color: @color-darkish-grey;
  }

  .searchBarLink {
    color: @color-white !important;
  }
}

.searchInputHeader {
  height: 40px !important;
  padding: 10px 12px !important;

  ::placeholder {
    font-size: 14px !important;
  }

  .anticon {
    font-size: 16px !important;
  }
}

.searchInputCatalogue {
  height: 44px !important;
  padding: 10px 12px !important;
}

.list {
  height: 46vh;
  overflow: auto;
}

@media (min-width: 780px) {
  .searchInput {
    padding: 14px 8px;
    border-radius: 8px;
    height: 40px;
    width: 960px;
    font-size: 16px;
  }

  .searchIcon {
    width: 20px;
  }
}

.searchBarLink {
  color: @color-white !important;
  font-size: 14px !important;
}

.searchWrapper {
  margin-top: -18px;
  border-top: 1px solid rgb(0 0 0 / 10%);
  width: calc(100% - 50px);

  .icon {
    margin-right: 20px;
    margin-left: 6px;
  }

  .recent {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0px 2px 0;
    margin-bottom: 5px;
  }

  .recommendations {
    padding: 2px 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 32%;
  }

  .recommendationsText {
    background-color: @color-white-lighter !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    height: 26px;
    color: @color-medium-grey;

    .doubleArrow {
      padding: 1px;
      margin-top: 3px;
    }
  }

  .indicatorIcon {
    background: @color-icon !important;
    color: @color-white;
  }

  .nestedList {
    padding-left: 32px !important;

    .customLink:hover {
      cursor: pointer;
      color: @color-primary;
    }

    li {
      padding: 5px 0px !important;
    }

    :global {
      .ant-list-item-meta-avatar {
        margin-right: 10.25px;
      }
    }
  }

  .meta {
    align-items: center;
  }

  .listItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px !important;
  }

  .listItem:hover {
    background: @color-light-white;
    cursor: pointer !important;

    .recommendationsText {
      background-color: @color-background !important;
      color: @color-medium-grey;
    }
  }

  .hintItem {
    .tagStyleMixin();
  }

  .hintIcon {
    background: @color-icon !important;
    color: @color-white;
    margin-bottom: 3px !important;
  }

  .collapse {
    width: 100%;
  }

  .searchPanel {
    >div {
      padding: 0px !important;
    }
  }

  .collection {
    padding-right: 35px;
  }

  .collectionIcon {
    margin-right: 16px !important;
    color: @color-white;
  }

  .spacers {
    padding: 0px 25px;
  }

  .textTruncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 68%;
  }

  .viewCollection {
    padding-left: 8% !important;
  }
}

.hintItem {
  .tagStyleMixin();
}

.filterHintItem {
  background: none;
  border: 1px solid @color-searchbar-icon !important;
  cursor: pointer;
  border-radius: 8px !important;
  padding: 4px 10px 4px 10px !important;
  display: inline-flex !important;
  align-items: center;
}

.closeBtn {
  margin-top: 6px !important;
  font-size: 10px !important;
}

:global {
  .search-popover {
    :global {
      .ant-popover-inner-content {
        padding: 0px !important;
      }

      .ant-popover-inner .ant-popover-inner-content {
        padding: 0px !important;
      }

      .ant-popover-inner {
        border-radius: 0px 0px 8px 8px !important;
        box-shadow: 0px 5px 7px rgb(0 0 0 / 10%) !important;
      }

      .ant-popover-content {
        border: none !important;
      }

      .ant-popover-inner-content {
        padding: 0px !important;
      }

      .ant-popover {
        padding: 0px !important;
        margin-top: -47px !important;
      }

      .ant-list-item-meta-title {
        font-weight: 400;
      }

      h5.ant-typography {
        color: @color-white !important;
      }

      .ant-input {
        font-size: 18px !important;
      }

      .listFont {
        font-size: 16px;
      }

      .anticon.ant-input-clear-icon {
        margin: 0;
        color: rgba(0, 0, 0, 0.25);
        font-size: 20px !important;
        vertical-align: -1px;
        cursor: pointer;
        transition: color 0.3s;
      }
    }
  }
}

.displayItem::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: @color-gray-light;
}

.HomenewDropdown {
  :global {
    .ant-popover-inner {
      padding-bottom: 15px;
      border-radius: 0 0 32px 32px !important;
    }
  }
}
@primary-color: #2E5CF6;