@import '../../Colors.less';

.headerContainer {
  height: 60px;
  background-color: @color-blue;

  .logo {
    margin-left: 5px;
    margin-right: -15px;
    // width: 130px;
    height: 50px;
  }

  :global {
    .ant-radio-button-wrapper {
      border: none !important;
      background-color: @transparent-hex;
      color: @white-hex;
    }

    .headerHeight {
      height: 40px;
    }

    .headerHeightCatalogue {
      height: 40px;
      background: @color-light-bluish;
      color: @color-white;
      border: 0;
    }

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
      text-decoration: none;
      background: @color-light-bluish;
      color: @color-white;
    }

    .ant-btn>.anticon {
      margin-top: 5px !important;
    }
  }

  .plusFont::after {
    content: '+';
    position: absolute;
    top: -2px;
    font-size: 0.76em;
  }

  .minusFont::after {
    content: '-';
    position: absolute;
    top: -2px;
    font-size: 0.76em;
  }

  .profileText {
    color: @white-hex;
  }

  .menuIcon {
    font-size: 16px;
    margin-right: 10px;
    color: @white-hex;
  }
}

.disabledButton {
  :global {
    .ant-btn[disabled] {
      background: none !important;
      border: 0px !important;
      color: @white-hex;
    }
  }
}

.menuContainer {
  .header {
    background-color: @color-blue;
    height: 60px;
  }

  :global {
    .ant-row {
      margin-bottom: 20px !important;
    }

    .ant-page-header {
      background-color: @primary-color-hex !important;
    }
  }

  .headerTextFirstLine {
    margin-top: 7px;
    line-height: 1 !important;
    color: @white-hex;
  }

  .headerTextSecondLine {
    line-height: 1 !important;
    display: inline-block;
    color: @white-hex;
  }

  .fontSizeText {
    margin-right: 10px;
  }

  .plusFont::after {
    content: '+';
    position: absolute;
    top: -0.526em;
    font-size: 0.76em;
  }

  .minusFont::after {
    content: '-';
    position: absolute;
    top: -0.526em;
    font-size: 0.76em;
  }

  .closeButton {
    padding: 0 !important;
    margin-left: 25px;
  }

  .closeIcon {
    margin-top: 5px;
    font-size: 20px;
    color: @white-hex;
  }

  .menuText {
    text-align: center;
    margin: 10px 0 10px 0;
  }
}

.responsiveDrawer {
  :global {
    .ant-drawer-body {
      padding: 0px;
    }
  }
}

:global {
  .ant-drawer-header-close-only {
    display: none !important;
  }

  .ant-slider-mark {
    .ant-slider-mark-text:nth-of-type(1) {
      padding-left: 17px;
      background-color: @white-hex;
    }

    .ant-slider-mark-text:nth-of-type(2) {
      padding-right: 15px;
      background-color: @white-hex;
    }
  }

  .ant-table-wrapper {
    border: 1px solid @color-greyish-dark;
    border-radius: 12px;
    overflow: hidden;
  }

  .ant-table-ping-right .ant-table-container::after {
    box-shadow: none !important;
  }

  .ant-table-ping-left .ant-table-container::before {
    box-shadow: none !important;
  }

  .ant-table-row:nth-child(odd),
  .virtual-table-cell-odd {
    background: @color-lightish-grey;
  }

  .ant-table-thead {
    th {
      background-color: @color-light-white !important;
    }
  }

  .filter-table,
  .table-grey-theme {
    .ant-table-thead {
      th {
        background-color: @color-lightish-grey !important;
      }
    }
  }

  .ant-input-clear-icon {
    font-size: 21px !important;
  }

  .ant-popover-arrow-content {
    display: none !important;
  }

  .font-weight-semi-bold {
    font-weight: 600 !important;
  }

  .rounded-common {
    border-radius: 8px !important;
  }

  .commonbtn-dark {
    background: @color-primary;
    color: @color-white;
    height: 48px !important;
  }

  .commonbtn-light {
    background: @color-white-lighter;
    color: @color-primary;
    height: 48px !important;
  }

  .listcircle {
    width: 6px;
    height: 6px;
    background-color: @color-black;
    border-radius: 50%;
    display: inline-block;
  }

  .CommonSearch {
    .ant-input-affix-wrapper {
      padding-left: 42px;
      border-radius: 8px !important;
      height: 42px !important;

      input::placeholder {
        font-size: 14px;
      }
    }

    .ant-input-group-addon {
      display: block;
      left: 8px !important;
      position: absolute;
      top: 5px;
      border: 0;
      z-index: 2;
    }

    .ant-input-search-button {
      border: 0;
      width: 30px;
    }

    .anticon-search {
      font-size: 16px;
      color: @color-darkish-grey;
    }
  }

  .CommonSearchDefault {
    .ant-input-affix-wrapper {
      padding-left: 8px;
      border-radius: 8px !important;
      height: 42px !important;

      input::placeholder {
        font-size: 14px;
      }
    }

    .anticon-search {
      font-size: 16px;
      color: @color-darkish-grey;
      width: 30px;
    }
  }

  .card-notruncate {
    .ant-card-meta-title {
      white-space: normal;
    }
  }

  .lg-btn-height {
    height: 48px !important;
  }

  #userback_button_container .userback-button {
    border-radius: 5px 5px 0px 0px !important;
    top: 290px !important;
  }

  @media (max-width: 767px) {
    #userback_button_container .userback-button {
      font-size: 0px !important;
      min-width: 50px !important;
      top: 81% !important;
    }

    #userback_button_container .userback-button[wstyle='text_icon'] svg {
      margin: 11px 2px 0px 2px !important;
    }
  }

  .ant-btn[disabled] .customIcon {
    filter: grayscale(100%);
  }

  .ant-btn[disabled] {
    img {
      filter: grayscale(100%);
    }
  }

  .greyFilter {
    filter: grayscale(100%);
  }

  .introjs-tooltiptext {
    padding: 10px 20px 0px !important;
  }

  .primary-icon {
    color: @color-primary !important;
    background-color: @color-lighten-grey !important;
  }

  .ant-tooltip {
    z-index: 999 !important;
  }

  .granularitySearch {
    .searchWrapperCustom {
      width: 50vw !important;
    }
  }

  .ant-dropdown-menu-item-only-child.selected {
    h4 {
      color: @color-primary !important;
      font-weight: 600;
    }
  }
}
@primary-color: #2E5CF6;