@import '../../Colors.less';

.datasetList {
  .highlight {
    min-height: 80px;
    max-height: 95px;
    overflow: hidden;
    padding-top: 10px !important;
    padding-right: 10px !important;
    margin-left: -20px !important;
    margin-bottom: 4px !important;
    padding-bottom: 10px !important;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0.25rem 3.75rem 0 rgb(0 0 0 / 16%);
      background-color: #fff;
      border-radius: 4px;
    }
    .listIcon {
      top: 10px;
    }
  }
  .dataItem {
    border-color: #e2e4e7;
    position: relative;
    padding-left: 60px;
  }
}
.listIcon {
  position: absolute;
  top: 0px;
  left: 2px;

  span {
    color: @color-primary;
    font-size: 40px;
  }
}
.highlightMobile{
  max-height: initial !important;
}
.displayIcon {
  position: absolute;
  left: 15px;
  top: 7px;
}
@primary-color: #2E5CF6;