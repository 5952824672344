@import '../../Colors.less';

.comingSoonContainer {
  height: 400px;
  width: 80%;

  .icon {
    span {
      svg {
        height: 150px;
        width: 150px;
        color: @color-parrot-green;
      }
    }
  }
}

@primary-color: #2E5CF6;