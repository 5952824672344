@import '../../../Colors.less';

.screenReaderContainer {
  .content {
    overflow-y: auto;
    height: calc(100vh - 120px);
    border-top: 2px solid @solitude-hex;
    padding: 20px;
  }
  .closeIcon {
    color: @black-hex;
  }
  .typeText {
    color: @color-gray-light;
    margin-left: 10px;
    display: inline-block;
  }
}

@primary-color: #2E5CF6;