@import '../../Colors.less';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.loginContainer {
  .closeIcon {
    color: @black-hex;
  }

  :global {
    .ant-form-item {
      margin-bottom: 12px !important;
    }

    .ant-tooltip {
      max-width: 350px !important;
    }
  }
}

.avatar {
  cursor: pointer;
  background-color: @transparent-hex !important;
}

.borderedRow {
  margin-top: 10px;
  border-radius: 4px;
  border: 2px solid @solitude-hex;
}

.marginLeft {
  margin-left: -32px;
}

.paddedRow {
  padding: 10px;
}

.myProfile {
  :global {
    .ant-row+.ant-row {
      margin-bottom: 0px !important;
    }

    .ant-tooltip {
      max-width: 350px !important;
    }
  }
}

.forgotLink {
  padding-top: 0.4em;
}

.loginModal {
  top: -35px;
}

.toolTip {
  max-width: 300 !important;
}

:global {
  .loginpopup {
    position: absolute !important;
    top: 50px;
    z-index: 999;
    right: 2px;
    width: 300px;

    button {
      font-weight: 500;
    }

    .cancelBtn {
      color: @color-medium-grey;
    }
  }
}
@primary-color: #2E5CF6;