@import '../../Colors.less';

.multiselectListContainer {
  max-height: 28vh;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.showHideWidthApp {
  width: 490px;
}

.checkboxes {
  line-height: 24px;
  width: 20px;
}

.matchingText {
  max-width: calc(100% - 120px);
  margin-bottom: -5px;
}

:global {
  .ant-popover-disabled-compatible-wrapper.w-100 {
    padding: 0px !important;
  }

  .ant-popover-disabled-compatible-wrapper.w-100 button {
    width: 100% !important;
  }

  .ant-popover-disabled-compatible-wrapper.text-left button {
    text-align: left !important;
  }

  .ant-badge-count {
    background: @color-primary;
  }
}

@primary-color: #2E5CF6;