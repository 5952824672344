@import '../../Colors.less';

.headerTextFirstLine {
  line-height: 5px;
  margin-top: 14px;
  font-size: 15px;
  color: @white-hex;
  font-size: 15px;
  font-weight: 600;
}

.headerTextSecondLine {
  line-height: 1 !important;
  display: inline-block;
  color: @white-hex;
}

.fatFont {
  font-size: 11px !important;
}

@primary-color: #2E5CF6;